<template>
  <slot></slot>

  <template v-if="isContactFormLoaded && state.user?.type === 'enterprise'">
    <ContactFormDialog
      v-model:success="success"
      :subject="contactFormData?.subject"
      :message="contactFormData?.message"
      :additional-information="contactFormData?.additionalInformation"
      :is-open="isContactFormOpen"
      @on-request-close="close"
    />
  </template>
  <template v-if="isContactFormLoaded && state.user?.type === 'community'">
    <DiscordLinkDialog :is-open="isContactFormOpen" @on-request-close="close" />
  </template>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import { setupContactForm } from './'
import { useStore } from '~/store/useStore'

const ContactFormDialog = defineAsyncComponent(() => import('./ContactFormDialog.vue'))
const DiscordLinkDialog = defineAsyncComponent(() => import('./DiscordLinkDialog.vue'))

const { isContactFormLoaded, isContactFormOpen, contactFormData, success, close } =
  setupContactForm()

const { state } = useStore()
</script>
