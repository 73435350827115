import { useIntervalFn } from '@vueuse/core'
import { computed, readonly, ref } from 'vue'

import config from '~/config'

const { maintenanceDate = new Date('2000-01-01') } = config

function getDaysUntilMaintenance(date: Date) {
  const today = new Date(new Date().toISOString().split('T')[0])
  return Math.round((date.getTime() - today.getTime()) / (1000 * 3600 * 24))
}

export function useMaintenance() {
  const daysUntil = ref(getDaysUntilMaintenance(maintenanceDate))

  const interval = useIntervalFn(() => {
    daysUntil.value = getDaysUntilMaintenance(maintenanceDate)
  }, 3600)

  return {
    maintenanceDate: maintenanceDate,
    daysUntil: readonly(daysUntil),
    inFuture: computed(() => daysUntil.value >= 0),
    inPast: computed(() => daysUntil.value < 0),
    interval,
  }
}
