import { captureException, setUser } from '@sentry/vue'
import debug from 'debug'
import merge from 'lodash/merge'
import { reactive, toRaw } from 'vue'

import { api } from '../api/quantistry'

export type Account = Awaited<ReturnType<typeof api.account.get>>
type AccountUpdateRequest = Parameters<typeof api.account.update>[0]['requestBody']

const log = debug('quantistry:store')

interface StoreState {
  isAuthenticated: boolean
  user?: Account
}

class Store {
  state = reactive<StoreState>({
    isAuthenticated: false,
  })

  async initialize() {
    log('initializing')
    try {
      const user = await this.fetchUser()
      setUser({ email: user.email, id: user.id, type: user.type })
      this.state.user = user
      this.state.isAuthenticated = true
    } catch {
      this.state.isAuthenticated = false
    }
    log('initialized')
  }

  async fetchUser() {
    const user = await api.account.get()
    return user
  }

  async refetchUser() {
    const user = await this.fetchUser()
    this.state.user = user
  }

  async updateUser(userData: AccountUpdateRequest) {
    // optimistic updates
    let newUser = structuredClone(toRaw(this.state.user))
    try {
      this.state.user = merge({}, newUser, userData)
      newUser = await api.account.update({ requestBody: userData })
    } catch (error) {
      captureException(error)
    }
    this.state.user = newUser
  }
}

const store = new Store()

export function useStore() {
  return store
}
