import { useStorage } from '@vueuse/core'
import { computed } from 'vue'

import { useStore } from '~/store/useStore'

const { state } = useStore()

export const featureFlagsId = computed(() => `${state.user?.id ?? 'unknown'}-enabled_features_list`)

// Example name, replace with new feature name
export type Feature = 'new-feature'

export function useFeatureFlags() {
  const defaultList: Partial<Record<Feature, boolean>> = state.user?.internal_permissions
    ? { 'new-feature': true }
    : {}

  const featureList = useStorage(featureFlagsId.value, defaultList, localStorage, {
    mergeDefaults: true,
  })

  return {
    isFeatureEnabled(id: Feature) {
      return featureList.value[id] === true
    },
    toggleFeature(id: Feature) {
      if (this.isFeatureEnabled(id)) {
        featureList.value[id] = false
      } else {
        featureList.value[id] = true
      }
    },
  }
}
