import { useStorage } from '@vueuse/core'
import { computed, inject, onMounted, onUnmounted, provide, readonly, ref, Ref, watch } from 'vue'
import type { InjectionKey } from 'vue'

import { useStore } from '~/store/useStore'
import { useMatomo } from '~/utils/tracking'

export type SidebarType = 'slide-over' | 'default'

const SIDEBAR_CONTEXT_KEY = Symbol() as InjectionKey<{
  isSidebarOpen: Readonly<Ref<boolean>>
  sidebarType: Readonly<Ref<SidebarType>>
  toggleSidebarVisibility: () => void
  setSidebarType: (type: SidebarType) => void
}>

export function setupSidebar() {
  const matomo = useMatomo()
  const store = useStore()

  const isDefaultSidebarOpen = useStorage(`${store.state.user?.id ?? 'unknown'}-sidebar_open`, true)
  const isSlideOverSidebarOpen = ref(false)
  const isSidebarAnimating = ref(false)

  const sidebarType = ref<SidebarType>('default')

  const transitionDuration = 250

  //To use in v-bind
  const transition = computed(() => {
    return `${transitionDuration}ms`
  })

  watch(isDefaultSidebarOpen, (current, previous, onCleanup) => {
    isSidebarAnimating.value = true
    const timeout = setTimeout(() => {
      isSidebarAnimating.value = false
    }, transitionDuration)

    onCleanup(() => clearTimeout(timeout))
  })

  const ctx = {
    isSidebarOpen: computed(() => {
      if (sidebarType.value === 'default') {
        return isDefaultSidebarOpen.value
      } else {
        return isSlideOverSidebarOpen.value
      }
    }),
    sidebarType: readonly(sidebarType),

    toggleSidebarVisibility() {
      matomo.trackEvent('navigation-sidebar', isDefaultSidebarOpen.value ? 'hide' : 'show')
      if (sidebarType.value === 'default') {
        isDefaultSidebarOpen.value = !isDefaultSidebarOpen.value
      } else {
        isSlideOverSidebarOpen.value = !isSlideOverSidebarOpen.value
      }
    },
    setSidebarType(type: SidebarType) {
      sidebarType.value = type
      if (type === 'slide-over') {
        isSlideOverSidebarOpen.value = false
      }
    },
  }

  provide(SIDEBAR_CONTEXT_KEY, ctx)

  return { transition, isDefaultSidebarOpen, isSidebarAnimating, isSlideOverSidebarOpen, ...ctx }
}

export function useSidebar(type?: SidebarType) {
  const ctx = inject(SIDEBAR_CONTEXT_KEY)

  if (!ctx) throw new TypeError('No sidebar context provided!')

  if (type) {
    onMounted(() => {
      ctx.setSidebarType(type)
    })

    onUnmounted(() => {
      ctx.setSidebarType('default')
    })
  }

  return ctx
}
