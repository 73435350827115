<template>
  <aside class="maintenance">
    <WarningIcon class="h-5 w-5 shrink-0" />
    <div>
      <p class="font-semibold">Scheduled Maintenance {{ days }}</p>
      <p>
        Our platform will be down for maintenance on
        <span class="font-semibold">{{ dateString }}</span>
        during regular business hours. We appreciate your patience! For updates or questions,
        contact Support.
      </p>
    </div>
  </aside>
</template>

<script setup lang="ts">
import WarningIcon from 'ionicons/warning-outline.svg'

import { formatDate } from '~/utils/utils'

const { date, daysUntil } = defineProps<{
  date: Date
  daysUntil: number
}>()

const dateString = formatDate(date)

const formatter = new Intl.RelativeTimeFormat('en-US', {
  style: 'long',
  numeric: 'auto',
})

const days = formatter.format(daysUntil, 'days')
</script>

<style scoped>
.maintenance {
  display: flex;
  gap: theme('spacing.2');
  align-items: center;
  font-size: theme('fontSize.sm');
  color: theme('colors.primary.50');
  background-color: theme('colors.primary.600');
  padding: theme('spacing.2') theme('spacing.5');
}
</style>
