<template>
  <div v-if="organizations && favoriteProjects" class="min-h-full w-full">
    <a
      class="q-focus absolute left-0 top-0 z-50 w-[250px] -translate-y-[calc(100%+1px)] whitespace-nowrap bg-amber-500 px-2 py-1 text-sm font-semibold text-neutral-700 transition-transform hover:bg-amber-600 focus:translate-y-0 active:bg-amber-700"
      href="#main"
    >
      Skip to main content
    </a>
    <div
      class="shell min-h-full w-full"
      :class="{ 'has-slide-over': sidebarType === 'slide-over' }"
    >
      <div class="banner">
        <MaintenanceBanner v-if="inFuture" :date="maintenanceDate" :days-until="daysUntil" />
      </div>
      <div
        v-if="sidebarType === 'default'"
        class="resizable-container"
        :class="{
          'w-[260px]': isDefaultSidebarOpen,
          'w-0': !isDefaultSidebarOpen,
          invisible: !isDefaultSidebarOpen && !isSidebarAnimating,
        }"
      >
        <NavigationSidebar
          :favorite-projects="favs"
          :organizations="sortedOrganizations"
          :internal-tools="internalTools"
          @contact-support="contactForm.open"
          @create-project="openCreateProject"
          @toggle-visibility="toggleSidebarVisibility"
        />
      </div>
      <template v-else>
        <TransitionRoot appear :show="isSlideOverSidebarOpen" as="template">
          <Dialog @close="toggleSidebarVisibility">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="fixed inset-0 bg-black bg-opacity-50" />
            </TransitionChild>
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="slide-initial"
              enter-to="slide-final"
              leave="duration-300 ease-in"
              leave-from="slide-final"
              leave-to="slide-initial"
            >
              <DialogPanel class="fixed inset-0 w-[260px]">
                <NavigationSidebar
                  class="rounded-br rounded-tr"
                  :favorite-projects="favs"
                  :organizations="sortedOrganizations"
                  :internal-tools="internalTools"
                  @contact-support="contactForm.open"
                  @create-project="openCreateProject"
                  @toggle-visibility="toggleSidebarVisibility"
                />
              </DialogPanel>
            </TransitionChild>
          </Dialog>
        </TransitionRoot>
      </template>

      <router-view />
    </div>
  </div>
  <div v-else class="flex h-screen w-full items-center justify-center">
    <Spinner :size="32" class="text-neutral-500" />
  </div>

  <CreateProjectModal
    v-if="!!createProjectForOrganizationId"
    :affiliation-id="createProjectForOrganizationId!"
    @close="closeCreateProject"
  />

  <InternalOrganizationSelector
    v-if="isInternalOrganizationSelectorOpen"
    is-open
    @on-request-close="isInternalOrganizationSelectorOpen = false"
  />

  <FeatureFlagsModal
    v-if="isFeatureToggleOpen"
    :feature-list="featureList"
    @on-request-close="isFeatureToggleOpen = false"
  />

  <!-- only render terms modal when we *have a user* and they didn't accept yet -->
  <TermsAndConditionsModal v-if="store.state.user?.accepted_terms_and_conditions === false" />
</template>

<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { computed, defineAsyncComponent, ref } from 'vue'

import { useContactForm } from './ContactForm'
import { Feature } from './FeatureFlags/use-feature-flags'
import { useMaintenance } from './MaintenanceBanner/maintenance'
import MaintenanceBanner from './MaintenanceBanner/MaintenanceBanner.vue'
import NavigationSidebar, { InternalToolsList } from './NavigationSidebar/NavigationSidebar.vue'
import { setupSidebar } from './NavigationSidebar/use-sidebar'
import Spinner from './Spinner.vue'
import { useOrganizations } from '~/api/organizations'
import { formatProject, useProjects } from '~/api/projects'
import { useStore } from '~/store/useStore'
import { useProjectInvitationResult } from '~/utils/hooks'
import { useAppUpdate } from '~/utils/use-app-update'

const CreateProjectModal = defineAsyncComponent(() => import('./CreateProjectModal.vue'))
const InternalOrganizationSelector = defineAsyncComponent(
  () => import('~/components/InternalOrganizationSelector.vue')
)

const FeatureFlagsModal = defineAsyncComponent(
  () => import('~/components/FeatureFlags/FeatureFlagsModal.vue')
)

const TermsAndConditionsModal = defineAsyncComponent(
  () => import('~/components/TermsAndConditionsModal.vue')
)

const store = useStore()
const isInternalOrganizationSelectorOpen = ref(false)
const createProjectForOrganizationId = ref<string | null>(null)

useAppUpdate()

const { data: organizations } = useOrganizations()
// we always want to show the personal account first
const sortedOrganizations = computed(() => {
  const personalAccount = organizations.value?.list.find((org) => org.type === 'personal')
  const teams = organizations.value?.list.filter((org) => org.type === 'team') ?? []

  if (!personalAccount) {
    return teams
  }

  return [personalAccount, ...teams]
})
const { data: favoriteProjects } = useProjects({ isFavorite: true })
const favs = computed(
  () =>
    favoriteProjects.value?.list.map((project) =>
      formatProject(project, project.organization.id, { addFavoriteSuffix: true })
    ) ?? []
)

// Navigation sidebar
const {
  transition,
  sidebarType,
  toggleSidebarVisibility,
  isDefaultSidebarOpen,
  isSidebarAnimating,
  isSlideOverSidebarOpen,
} = setupSidebar()

//Feature flags

const isFeatureToggleOpen = ref(false)

const featureList: { value: Feature; label: string }[] = []

const featureFlags = {
  id: 'toggle-feature-flags',
  name: 'Feature Previews',
  action: () => {
    isFeatureToggleOpen.value = true
  },
}

const internalTools = computed(() => {
  if (!store.state.user?.internal_permissions) {
    return
  }

  const permissions = store.state.user.internal_permissions
  const tools: InternalToolsList[] = []

  if (permissions.includes('organizations:read') && permissions.includes('organizations:write')) {
    tools.push({
      id: 'switch-orgs',
      name: 'Switch Organizations',
      action: () => {
        isInternalOrganizationSelectorOpen.value = true
      },
    })
  }

  if (featureList.length) {
    tools.push(featureFlags)
  }

  return tools
})

// Dialogs
const contactForm = useContactForm()

function openCreateProject(affiliationID: string) {
  createProjectForOrganizationId.value = affiliationID
}

function closeCreateProject() {
  createProjectForOrganizationId.value = null
}

// Handle result of user accepting an invitation to a project.
const isDataReady = computed(() => !!organizations.value && !!favoriteProjects.value)
useProjectInvitationResult(isDataReady)

const { maintenanceDate, daysUntil, inFuture } = useMaintenance()
</script>

<style scoped>
.shell {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);

  &.has-slide-over {
    grid-template-columns: minmax(0, 1fr);
  }
}

.banner {
  grid-column: 1 / -1;
}

.resizable-container {
  @apply flex justify-end;

  transition: width v-bind(transition) ease-out;
}

.slide-initial {
  transform: translate3d(-100%, 0, 0);
}

.slide-final {
  transform: translate3d(0, 0, 0);
}
</style>
